<template>
  <main>
    <header-black />
    <light-car-descr />
    <light-car-list />
    <home-contact-us />
  </main>
</template>

<script>
export default {
  name: 'LightCarPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    LightCarDescr: () => import('@/components/LightCarDescr'),
    LightCarList: () => import('@/components/LightCarList'),
    HomeContactUs: () => import('@/components/HomeContactUs')
  }
}
</script>
